import React from 'react';

import EmailForm from './email/EmailForm';

const Body: React.FC = () => {
  return (
    <section className="w-full overflow-hidden">
      <div className="w-full mt-28 lg:mt-64 flex justify-center items-center">
        <div className="w-full max-w-6xl px-4">
          <div className="flex flex-col lg:flex-row">
            <div className="mt-10 grid flex-grow h-32 card rounded-box place-items-center">
              <span className="text-5xl lg:text-7xl font-black font-nunito text-inntech-name mb-4 float-left">InnTech</span>
              <div className="mt-4">
                <span className="text-3xl lg:text-4xl font-black text-whitesmoke-hard font-nunito uppercase">& Software Development Focused </span>
                <span className="text-4xl lg:text-5xl font-black text-whitesmoke-hard font-nunito uppercase">On Your Success</span>
              </div>
            </div>
          </div>

          {/* 
          <div className="pt-10 h-72 w-full flex justify-center bg-orange-200">
            <div className="max-w-4xl">
              <span className="text-white text-xl text-justify">
                At InnTech, our mission is to go beyond simply providing IT services. We are committed to
                connecting the possibilities of the digital world, building solutions that drive growth and success
                for our clients.
                With an approach centered on innovation and technical excellence, we are transforming the digital
                future, creating an environment where challenges become opportunities and ideas become reality.
                Join us as we navigate together through the frontiers of technology, empowering your business to
                reach new levels of efficiency and competitiveness.
              </span>
            </div>
          </div>
           */}

          <div className="mt-60 lg:mt-80">
            <div className="max-w-5xl">
              <div className="mb-5" id="about">
                <span className="text-white font-extrabold text-xl lg:text-2xl">About Us</span>
              </div>
              <div className="text-white">
                <p>Welcome to InnTech, where our mission goes beyond simply providing IT services.</p>
                We are committed to connecting the possibilities of the digital world, building solutions that drive growth and success for our clients.
                <p>With an approach centered on innovation and technical excellence, we are transforming the digital future, creating an environment where challenges become opportunities and ideas become reality. </p> <br />
                <p>Since our foundation, we have cultivated a culture of innovation and continuous learning, constantly exploring new technologies and methodologies to ensure that our clients have access to the most advanced and effective solutions on the market.</p>
                Our team consists of talented and passionate professionals, ready to tackle any challenge and deliver exceptional results. Each member of our team brings with them extensive experience and specialized knowledge in various areas of information technology, ensuring that we can offer comprehensive and personalized service to meet the specific needs of each client.
                <p>At InnTech, we don't just offer solutions, we also build lasting partnerships based on trust, commitment, and tangible results.</p> < br />
                We believe that our clients' success is our own success. That's why we are committed to providing exceptional service, building strong relationships, and offering solutions that add real value to our clients' businesses.
                If you're looking for a reliable and innovative partner to help you achieve your business goals in the digital world, you've come to the right place. We look forward to working with you and helping you reach new heights of digital success.
              </div>
            </div>
          </div>

          <div className="mt-16 lg:mt-60">
            <div className="w-full sm:w-2/2">
              <span className="ml-5 mb-3 text-white font-bold text-xl lg:text-2xl" id="works">Our Works</span>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

                <div className="flex flex-col p-6">
                  <span className="text-inntech-name font-bold">Initial Consultation</span>
                  <span className="w-10 h-2 mt-1 mb-2 bg-inntech-name" />
                  <span className="text-white">
                    It all starts with a conversation. We understand that each client is unique, so we take the time
                    to listen to your specific needs, goals, and challenges. During this phase, we discuss your ideas,
                    expectations, and desired outcomes. This initial consultation allows us to build a solid foundation
                    for the project and establish a relationship of trust with the client.
                  </span>
                </div>

                <div className="flex flex-col  p-6">
                  <span className="text-inntech-name font-bold">Execution</span>
                  <span className="w-10 h-2 mt-1 mb-2 bg-inntech-name" />
                  <span className="text-white">
                    In this phase, we put our plan into action. We implement the solution with precision and professionalism,
                    ensuring that every detail is executed as planned. Our highly skilled team uses best practices and
                    advanced technologies to deliver exceptional results. During execution, we maintain open communication
                    with the client, providing regular updates on the project's progress.
                  </span>
                </div>

                <div className="mt-2 flex flex-col p-6">
                  <span className="text-inntech-name font-bold">Evaluation and Feedback</span>
                  <span className="w-10 h-2 mt-1 mb-2 bg-inntech-name" />
                  <span className="text-white">
                    After project completion, we conduct a thorough evaluation of the results achieved.
                    We monitor the performance and effectiveness of the implemented solution, ensuring
                    it meets or exceeds expectations. We seek feedback from clients to identify areas
                    for improvement and ensure their total satisfaction. This phase is crucial to our
                    ongoing commitment to quality and excellence.
                  </span>
                </div>

                <div className="mt-2 flex flex-col p-6">
                  <span className="text-inntech-name font-bold">Planning</span>
                  <span className="w-10 h-2 mt-1 mb-2 bg-inntech-name" />
                  <span className="text-white">
                    With the information gathered from the initial consultation, our team convenes to develop a detailed
                    strategy and personalized action plan. We consider all aspects of the project, from required resources
                    to the timeline and budget. This meticulous planning ensures that everyone is aligned and prepared for
                    the next phase.
                  </span>
                </div>


                <div className="flex flex-col p-6">
                  <span className="text-inntech-name font-bold">Our Process</span>
                  <span className="w-10 h-2 mt-1 mb-2 bg-inntech-name" />
                  <span className="text-white text-justify">
                    Each project we undertake is treated with the utmost attention to
                    detail and a collaborative approach. From conception to completion,
                    we follow a rigorous process to ensure quality and efficiency.
                  </span>
                </div>

              </div>
            </div>
          </div>

          <div id="contact" className="mt-16 lg:mt-60 h-auto">
            <div className="p-5">
              <EmailForm />
            </div>
          </div>

        </div>
      </div>
    </section>

  );
}

export default Body;