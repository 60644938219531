import React, { useState } from 'react';
import logo from "../resources/images/logo.png";
import { AiOutlineClose } from "react-icons/ai";

import AnchorLink from './Useful/AnchorLink';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const subscribe = () => {
    return (
      <a className="text-black p-2 lg:btn lg:btn-rounded" onClick={() => {
        console.log("ShowModal!")
        if (document) {
          (document.getElementById('modal_subscribe') as HTMLFormElement).showModal()
        }
      }}>Subscribe</a>
    );
  }

  return (
    <header>
      <div className="navbar text-white justify-between items-center">
        <div className="flex">
          <a className="pl-0 btn btn-ghost text-xl" href='#'>
            <img src={logo} width={40} />
            <span className="text-xl lg:text-2xl font-black font-nunito text-white">InnTech</span>
          </a>
        </div>

        <div id="menu" className="hidden lg:flex mt-5">
          <div className="flex">
            <ul className="inline-flex leading-10">
              <li>
                <AnchorLink href="#about" children="About Us" classes="text-white p-4" />
              </li>
              <li>
                <AnchorLink href="#works" children="Our Works" classes="text-white p-4" />
              </li>
              <li>
                <AnchorLink href="#contact" children="Contact Us" classes="text-white p-4" />
              </li>
            </ul>
          </div>
        </div>

        <div className="lg:hidden">
          <button className="btn btn-rounded" onClick={toggleMenu}>Menu</button>
        </div>

        <div className="hidden lg:flex">
          {subscribe()}
        </div>
        <dialog id="modal_subscribe" className="modal">
          <div className="modal-box p-0 !h-auto">
            <div className="flex justify-between">
              <div className="ml-4 mt-1">
                <h3 className="font-bold text-lg text-detail-theme">Sign up here</h3>
              </div>
              <div className="">
                <form method="dialog">
                  <button className="p-2 rounded bg-gray-300 hover:bg-red-400">
                    <AiOutlineClose />
                  </button>
                </form>
              </div>
            </div>
            <div className="p-5 h-60 flex flex-col justify-around">
              <label className="input input-bordered flex items-center gap-2 text-detail-theme">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
                <input type="text" className="grow" placeholder="Name" />
              </label>
              <label className="input input-bordered flex items-center gap-2 text-detail-theme">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                <input type="text" className="grow" placeholder="Email" />
              </label>
              <button className="p-2 rounded w-full bg-detail-theme text-white hover:bg-detail-theme-hover focus:outline-none focus:ring focus:border-blue-300">Done</button>
            </div>
          </div>
        </dialog>
      </div>

      {isMenuOpen && (
        <div className="lg:hidden absolute top-16 right-0 bg-white p-2 rounded-md shadow-md">
          <ul>
            <li className="py-2">
              <AnchorLink href="#about" children="About Us" classes="text-black p-2" />
            </li>
            <li className="py-2">
              <AnchorLink href="#works" children="Our Works" classes="text-black p-2" />
            </li>
            <li className="py-2">
              <AnchorLink href="#contact" children="Contact Us" classes="text-black p-2" />
            </li>
            <li className="py-2">
              {subscribe()}
              <dialog id="modal_subscribe" className="modal">
                <div className="modal-box p-0 !h-auto">
                  <div className="flex justify-between">
                    <div className="ml-4 mt-1">
                      <h3 className="font-bold text-lg text-detail-theme">Sign up here</h3>
                    </div>
                    <div className="">
                      <form method="dialog">
                        <button className="p-2 rounded bg-gray-300 hover:bg-red-400">
                          <AiOutlineClose />
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="p-5 h-60 flex flex-col justify-around">
                    <label className="input input-bordered flex items-center gap-2 text-detail-theme">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
                      <input type="text" className="grow" placeholder="Name" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 text-detail-theme">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                      <input type="text" className="grow" placeholder="Email" />
                    </label>
                    <button className="p-2 rounded w-full bg-detail-theme text-white hover:bg-detail-theme-hover focus:outline-none focus:ring focus:border-blue-300">Done</button>
                  </div>
                </div>
              </dialog>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
