import React from 'react';

interface AnchorLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    classes?: string;
}

const AnchorLink: React.FC<AnchorLinkProps> = ({ href, children, classes, ...rest }) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        
        if (href === '#') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            const targetElement = document.querySelector(href);
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.getBoundingClientRect().top + window.pageYOffset,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <a href={href} onClick={handleClick} className={classes} {...rest}>
            {children}
        </a>
    );
};

export default AnchorLink;
