import React, { useState, useRef } from 'react';
//import emailjs from '@emailjs/browser';
import axios from 'axios';

interface EmailFormData {
  message: string;
  name: string;
  phone: string;
  email: string;
  budget: string;
  engage: string;
}

const EmailForm: React.FC = () => {
  const [formData, setFormData] = useState<EmailFormData>({
    message: '',
    name: '',
    phone: '',
    email: '',
    budget: '',
    engage: '',
  });

  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [sentError, setSentError] = useState(false);
  const form = useRef();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSending(true);
    try {
      console.log("Entrou no if");
      var response = await axios.post('https://mailer-api-six.vercel.app/mail/send', formData);
      if (response.status == 200)
        setSent(true);
      else
        setSentError(true);

    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <div className="max-w-3xl mx-auto p-4 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
      <h2 className="text-lg font-bold mb-4">Contact</h2>

      <h3 className="px-3 mt-3 font-semibold text-sm sm:text-lg">
        Explain in your own words the initial idea of the product you want us to develop and in which
        medium it will be used. This way, we will have a solid foundation of your idea and can pave
        your path to success. We will respond to your request within 24 hours.
      </h3>

      <form ref={form as any} onSubmit={handleSubmit} className="mt-10">
        <div className="mb-4">
          <label
            className="block text-white-700 text-sm font-bold mb-2"
            htmlFor="message"
          >
          </label>
          <textarea
            placeholder="Describe in detail the project you wish us to develop, please"
            className="textarea textarea-bordered textarea-sm w-full text-white-700 text-sm text-white-700 font-semibold leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4 flex justify-between">
          <div className="w-1/2 mr-2">
            <label
              className="block text-white-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-white-700 font-semibold text-white-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              placeholder="Your full name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-1/2 ml-2">
            <label
              className="block text-white-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-white-700 font-semibold text-white-700 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              type="tel"
              name="phone"
              placeholder="Phone number with the area code"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mb-4 flex justify-between">
          <div className="w-1/2 mr-2">
            <label
              className="block text-white-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-white-700 font-semibold text-white-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              placeholder="Your best email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-1/2 ml-2">
            <label
              className="block text-white-700 text-sm font-bold mb-2"
              htmlFor="budget"
            >
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-white-700 font-semibold text-white-700 leading-tight focus:outline-none focus:shadow-outline"
              id="budget"
              type="text"
              name="budget"
              placeholder="Budget Size"
              value={formData.budget}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-white-700 text-sm font-bold mb-2"
            htmlFor="engage"
          >
          </label>
          <input
            placeholder="How did you hear about us?"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-white-700 font-semibold leading-tight focus:outline-none focus:shadow-outline"
            id="engage"
            type="text"
            name="engage"
            value={formData.engage}
            onChange={handleChange}
          />
        </div>
        <div className="mt-10 flex justify-center">
          <button
            className="bg-detail-theme hover:bg-detail-theme opacity-90 hover:opacity-100 text-base lg:text-lg text-white font-bold btn w-3/4 rounded"
            type="submit"
            disabled={isSending}
          >
            {isSending ? 'Sending...' : 'Submit'}
          </button>
        </div>
        {sent && (
          <p className="text-green-600 text-sm mt-4">
            Email sent successfully
          </p>
        )}
        {sentError && (
          <p className="text-green-600 text-sm mt-4">
            Unable to send the email at the moment... Please try again later
          </p>
        )}
      </form>
    </div>
  );
};

export default EmailForm;