import React, { useState, useEffect } from "react";
import logo from "../resources/images/logo_dark.png";
import AnchorLink from "./Useful/AnchorLink";
import TermsOfUseModal from "../components/Useful/TermsOfUseModal";
import PrivacyPolicyModal from "../components/Useful/PrivacyPolicyModal";

const Footer: React.FC = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const openTermsModal = () => {
    setTermsModalOpen(true);
  };

  const openPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  const closeModal = () => {
    setTermsModalOpen(false);
    setPrivacyModalOpen(false);
  };

  return (
    <div>
      <footer className="footer p-4 bg-base-200 text-base-content">
        <aside>
          <div className="flex">
            <AnchorLink className="inline-flex min-h-12 h-12 my-4 lg:my-0 lg:pl-4 pr-4 items-center flex-wrap text-center text-xl" href="#">
              <img src={logo} width={40} />
              <span className="text-xl lg:text-2xl font-black font-nunito text-detail-theme">InnTech</span>
            </AnchorLink>
          </div>
        </aside>
        <nav>
          <h6 className="footer-title">Services</h6>
          <a className="link link-hover">Branding</a>
          <a className="link link-hover">Design</a>
          <a className="link link-hover">Marketing</a>
          <a className="link link-hover">Advertisement</a>
        </nav>
        <nav>
          <h6 className="footer-title">Company</h6>
          <a className="link link-hover" href="#about">About us</a>
          <a className="link link-hover" href="#contact">Contact</a>
          <a className="link link-hover" href="#works" >Our Works</a>
        </nav>
        <nav>
          <h6 className="footer-title">Legal</h6>
          <button className="link link-hover" onClick={openTermsModal}>Terms of use</button>
          <button className="link link-hover" onClick={openPrivacyModal}>Privacy policy</button>
        </nav>

        <TermsOfUseModal
          isOpen={termsModalOpen}
          onClose={closeModal}
        />

        <PrivacyPolicyModal
          isOpen={privacyModalOpen}
          onClose={closeModal}
        />

      </footer>
    </div>
  );
}

export default Footer;